import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 0,
  "title": "Work with metadata",
  "subtitle": "How to work with the OIDC or WS-Federation metadata endpoints"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For each domain you have in Criipto Verify, you can fetch token-issuer related metadata. These are used to configure your client to trust only tokens issued by your tenant, and they also contain information that many client-side libraries can use to run most of the login process automatically.`}</p>
    <p>{`The remainder of this article uses `}<inlineCode parentName="p">{`yourdomain.criipto.id`}</inlineCode>{` as a placeholder for your actual domain. Replace as needed before setting up your client(s) with metadata endpoints.`}</p>
    <h2>{`WS-Federation`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`(shorthand) `}<inlineCode parentName="p">{`https://yourdomain.criipto.id/metadata/wsfed`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`(ADFS-style) `}<inlineCode parentName="p">{`https://yourdomain.criipto.id/FederationMetadata/2007-06/FederationMetadata.xml`}</inlineCode></p>
        <p parentName="li">{`-`}{` note that both of the above return exactly the same data.`}</p>
      </li>
    </ol>
    <h2>{`OpenID Connect Discovery`}</h2>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`https://yourdomain.criipto.id/.well-known/openid-configuration`}</inlineCode></li>
    </ol>
    <h2>{`Login-method specific metadata`}</h2>
    <p>{`In some scenarios, you may have to use login-method specific metadata endpoints. This can be needed if the client does not allow you to specify `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` (OIDC) or `}<inlineCode parentName="p">{`wauth`}</inlineCode>{` (WS-Federation) dynamically.`}</p>
    <p>{`For those cases, you can leverage our login-method specific metadata endpoints. Each of these contain an embedded and base64-encoded variant of the 'raw' value normally supplied in the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` / `}<inlineCode parentName="p">{`wauth`}</inlineCode>{` query parameter. `}</p>
    <h3>{`WS-Federation`}</h3>
    <p>{`Syntax options:`}</p>
    <ol>
      <li parentName="ol">{`(shorthand) `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/metadata/wsfed/BASE64(wauth)`}</inlineCode></li>
      <li parentName="ol">{`(ADFS-style) `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/BASE64(wauth)/FederationMetadata/2007-06/FederationMetadata.xml`}</inlineCode></li>
    </ol>
    <ul>
      <li parentName="ul">{`note the different placements of the `}<inlineCode parentName="li">{`BASE64(wauth)`}</inlineCode>{` path segment in the 2 cases.`}</li>
    </ul>
    <h4>{`Example - Swedish BankID on another-device`}</h4>
    <p>{`Here, the `}<inlineCode parentName="p">{`wauth`}</inlineCode>{` value is `}<inlineCode parentName="p">{`urn:grn:authn:se:bankid:another-device`}</inlineCode>{`. This translates to `}<inlineCode parentName="p">{`dXJuOmdybjphdXRobjpzZTpiYW5raWQ6YW5vdGhlci1kZXZpY2U=`}</inlineCode>{` in base64 (UTF-8 charset), and the metadata endpoints are`}</p>
    <ol>
      <li parentName="ol">{`(shorthand) `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/metadata/wsfed/dXJuOmdybjphdXRobjpzZTpiYW5raWQ6YW5vdGhlci1kZXZpY2U=`}</inlineCode></li>
      <li parentName="ol">{`(ADFS-style) `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/dXJuOmdybjphdXRobjpzZTpiYW5raWQ6YW5vdGhlci1kZXZpY2U=/FederationMetadata/2007-06/FederationMetadata.xml`}</inlineCode></li>
    </ol>
    <h3>{`OpenID Connect Discovery`}</h3>
    <p>{`Syntax:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`https://yourdomain.criipto.id/BASE64(acr_values)/.well-known/openid-configuration`}</inlineCode></li>
    </ol>
    <h4>{`Example - Norwegian BankID`}</h4>
    <p>{`Here, the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` is `}<inlineCode parentName="p">{`urn:grn:authn:no:bankid`}</inlineCode>{`. This translates to `}<inlineCode parentName="p">{`dXJuOmdybjphdXRobjpubzpiYW5raWQ=`}</inlineCode>{` in base64 (UTF-8 charset), and the metadata endpoint is`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`https://yourdomain.criipto.id/dXJuOmdybjphdXRobjpubzpiYW5raWQ=/.well-known/openid-configuration`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      